import { Injectable } from '@angular/core';
import {
  PcContentCreateKind,
  PcGlobalPermissions,
  PcNavigationItem,
  PcShopSettingsTabKey,
  PcUserSettingsTabKey,
  PC_RESERVE_PICKUP_FALLBACK_SLUG,
} from '@pc-types';
import { RouteService } from '../route/route.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private routeService: RouteService) {}

  private getFullPath(path: string, id?: string): string[] {
    const segments = path.split('/').filter((p) => !!p);
    if (id) {
      segments.push(id);
    }

    return segments;
  }

  public getHomePath(): string[] {
    return ['/'];
  }

  public getFeedPath(): string[] {
    return this.getFullPath(this.routeService.routes.feed.url);
  }

  public getFeedViewPath(id: string): string[] {
    return this.getFullPath(this.routeService.routes.feedView.url, id);
  }

  public getContentPath(): string[] {
    return this.getFullPath(this.routeService.routes.content.url);
  }

  public getContentEditPath(id: string): string[] {
    return this.getFullPath(this.routeService.routes.contentEdit.url, id);
  }

  public getContentCreatePath(kind: PcContentCreateKind): string[] {
    return this.getFullPath(
      `${this.routeService.routes.contentEdit.url}/new/${kind}`
    );
  }

  public getAnnouncementsPath(): string[] {
    return this.getFullPath(this.routeService.routes.announcements.url);
  }

  public getAnnouncementEditPath(id: string): string[] {
    return this.getFullPath(this.routeService.routes.announcementEdit.url, id);
  }

  public getAnnouncementCreatePath(): string[] {
    return this.getFullPath(
      `${this.routeService.routes.announcementEdit.url}/new`
    );
  }

  public getJobsPath(): string[] {
    return this.getFullPath(this.routeService.routes.jobs.url);
  }

  public getUserManagementPath(): string[] {
    return this.getFullPath(this.routeService.routes.userManagement.url);
  }

  public getReportsPath(): string[] {
    return this.getFullPath(this.routeService.routes.reports.url);
  }

  public getReportsViewPath(shopId: string): string[] {
    return this.getFullPath(this.routeService.routes.reports.url, shopId);
  }

  public getInsightsPath(): string[] {
    return this.getFullPath(this.routeService.routes.insights.url);
  }

  public getTicketingPath(): string[] {
    return this.getFullPath(this.routeService.routes.ticketing.url);
  }

  public getTicketViewPath(id: string): string[] {
    return this.getFullPath(this.routeService.routes.ticket.url, id);
  }

  public getJobCreatePath(): string[] {
    return this.getFullPath(`${this.routeService.routes.jobEdit.url}/new`);
  }

  public getJobEditPath(id: string): string[] {
    return this.getFullPath(`${this.routeService.routes.jobs.url}/edit/${id}`);
  }

  public getJobViewPath(id: string): string[] {
    return this.getFullPath(this.routeService.routes.job.url, id);
  }

  public getKidsPath(): string[] {
    return this.getFullPath(this.routeService.routes.kids.url);
  }

  public getUserSettingsPath(tab: PcUserSettingsTabKey): string[] {
    return this.getFullPath(
      `${this.routeService.routes.userSettings.url}/${tab}`
    );
  }

  public getShopSettingsPath(tab: PcShopSettingsTabKey): string[] {
    return this.getFullPath(
      `${this.routeService.routes.shopSettings.url}/${tab}`
    );
  }

  public getReservePickupPath(id?: string): string[] {
    return this.getFullPath(
      `${this.routeService.routes.reservePickup.url}/${
        id ?? PC_RESERVE_PICKUP_FALLBACK_SLUG
      }`
    );
  }

  public getGastroUrl(): string[] {
    return this.getFullPath(this.routeService.routes.gastro.url);
  }

  public getPromotionsPath(): string[] {
    return this.getFullPath(this.routeService.routes.promotions.url);
  }

  public getPromotionViewPath(id: string): string[] {
    return this.getFullPath(this.routeService.routes.promotion.url, id);
  }

  public getPromotionCreatePath(): string[] {
    return this.getFullPath(
      `${this.routeService.routes.promotions.url}/edit/new`
    );
  }

  public getPromotionEditPath(id: string): string[] {
    return this.getFullPath(
      `${this.routeService.routes.promotions.url}/edit/${id}`
    );
  }

  public getLoginPath(): string[] {
    return this.getFullPath(this.routeService.routes.login.url);
  }

  public getPasswordResetPath(): string[] {
    return this.getFullPath(this.routeService.routes.passwordReset.url);
  }

  private getGeneralNavigationItems(): PcNavigationItem[] {
    return [
      {
        name: 'Tischreservierungen',
        pathFn: () => this.getGastroUrl(),
        activeUrl: this.routeService.routes.gastro.url,
      },
      {
        name: 'Reserve & Pickup',
        pathFn: () => this.getReservePickupPath(),
        activeUrl: this.routeService.routes.reservePickup.url,
      },
      {
        name: 'Jobinserate',
        pathFn: () => this.getJobsPath(),
        activeUrl: this.routeService.routes.jobs.url,
      },
      {
        name: 'Insights',
        pathFn: () => this.getInsightsPath(),
        activeUrl: this.routeService.routes.insights.url,
      },
      {
        name: 'Kinderland',
        pathFn: () => this.getKidsPath(),
        activeUrl: this.routeService.routes.kids.url,
      },
    ];
  }

  private getChannelContentNavigationItems(): PcNavigationItem[] {
    return [
      {
        name: 'Info-Feed',
        pathFn: () => this.getFeedPath(),
        activeUrl: this.routeService.routes.feed.url,
      },
      {
        name: 'Contentverwaltung',
        pathFn: () => this.getContentPath(),
        activeUrl: this.routeService.routes.content.url,
      },
      {
        name: 'Themenschwerpunkte',
        pathFn: () => this.getPromotionsPath(),
        activeUrl: this.routeService.routes.promotions.url,
      },
    ];
  }

  private getManagerNavigationItems(): PcNavigationItem[] {
    return [
      {
        name: 'Channel-Content',
        permission: {
          role: 'manager',
        },
        children: this.getChannelContentNavigationItems(),
      },
      {
        name: 'Manager',
        permission: {
          role: 'manager',
        },
        children: [
          {
            name: 'Announcements',
            pathFn: () => this.getAnnouncementsPath(),
            activeUrl: this.routeService.routes.announcements.url,
          },
          {
            name: 'Benutzerverwaltung',
            pathFn: () => this.getUserManagementPath(),
            activeUrl: this.routeService.routes.userManagement.url,
          },
          {
            name: 'Umsatzmeldungen',
            pathFn: () => this.getReportsPath(),
            activeUrl: this.routeService.routes.reports.url,
          },
          {
            name: 'Ticketing',
            pathFn: () => this.getTicketingPath(),
            activeUrl: this.routeService.routes.ticketing.url,
          },
        ],
      },
    ];
  }

  public getNavigationItems(
    globalPermissions: PcGlobalPermissions | undefined
  ): PcNavigationItem[] {
    if (!globalPermissions) {
      return [];
    }
    if (globalPermissions.role === 'manager') {
      // nav items manager
      return this.getManagerNavigationItems().concat(
        this.getGeneralNavigationItems()
      );
    } else {
      // nav items tenant
      return this.getChannelContentNavigationItems().concat(
        this.getGeneralNavigationItems()
      );
    }
  }
}
